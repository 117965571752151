import { StandardShelfCalculator } from './../Standard';
import { IPrice } from '../Standard/Model/IPrice';
import { IColumn } from "../Standard/Model/IColumn";
import { SurfaceTypesEnum } from '../Standard/Enum/SurfaceTypesEnum'

export class SalgoMetrikusShelfCalculator extends StandardShelfCalculator {
  // Polctalp
  private sole: IPrice[] = [];
  // Csavar
  private screwPrice: number = 0;
  // Saroklemez
  private cornerPrice: number = 0;
  // Oszlopok
  private columns: IColumn[] = [];

  constructor(props) {
    super(props);

    this.type = "salgo-metrikus";
    this.name = "Salgó metrikus polcrendszer tervező";
    this.previewImage = "/images/content/preview/salgo.jpg";

    this.surfaces = [SurfaceTypesEnum.Festett, SurfaceTypesEnum.Horganyzott];
    this.shelfsNumber = [4, 5, 6, 7];
    this.fieldsNumber = [1, 2, 3, 4];

    this.columns = [
      { height: 1981, price: [{ surface: SurfaceTypesEnum.Festett, price: 4149 }, { surface: SurfaceTypesEnum.Horganyzott, price: 2862 }] },
      { height: 2438, price: [{ surface: SurfaceTypesEnum.Festett, price: 4461 }, { surface: SurfaceTypesEnum.Horganyzott, price: 3081 }] },
    ];

    this.shelfs = [
      { width: 800, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 5527}, { surface: SurfaceTypesEnum.Horganyzott, price: 3564}] },
      { width: 800, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 6741}, { surface: SurfaceTypesEnum.Horganyzott, price: 4360}] },
      { width: 800, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 7922}, { surface: SurfaceTypesEnum.Horganyzott, price: 5130}] },
      { width: 800, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 9234}, { surface: SurfaceTypesEnum.Horganyzott, price: 5926}] },
      { width: 800, depth: 700, price: [{ surface: SurfaceTypesEnum.Festett, price: 10612}, { surface: SurfaceTypesEnum.Horganyzott, price: 6736}] },
      { width: 800, depth: 800, price: [{ surface: SurfaceTypesEnum.Festett, price: 11908}, { surface: SurfaceTypesEnum.Horganyzott, price: 7506}] },
      { width: 1000, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 6643}, { surface: SurfaceTypesEnum.Horganyzott, price: 4306}] },
      { width: 1000, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 8119}, { surface: SurfaceTypesEnum.Horganyzott, price: 5278}] },
      { width: 1000, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 9562}, { surface: SurfaceTypesEnum.Horganyzott, price: 6277}] },
      { width: 1000, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 11104}, { surface: SurfaceTypesEnum.Horganyzott, price: 7236}] },
      { width: 1000, depth: 700, price: [{ surface: SurfaceTypesEnum.Festett, price: 12761}, { surface: SurfaceTypesEnum.Horganyzott, price: 8221}] },
      { width: 1000, depth: 800, price: [{ surface: SurfaceTypesEnum.Festett, price: 14335}, { surface: SurfaceTypesEnum.Horganyzott, price: 9180}] },
      { width: 1200, depth: 300, price: [{ surface: SurfaceTypesEnum.Festett, price: 7741}, { surface: SurfaceTypesEnum.Horganyzott, price: 5089}] },
      { width: 1200, depth: 400, price: [{ surface: SurfaceTypesEnum.Festett, price: 9480}, { surface: SurfaceTypesEnum.Horganyzott, price: 6223}] },
      { width: 1200, depth: 500, price: [{ surface: SurfaceTypesEnum.Festett, price: 11202}, { surface: SurfaceTypesEnum.Horganyzott, price: 7384}] },
      { width: 1200, depth: 600, price: [{ surface: SurfaceTypesEnum.Festett, price: 13007}, { surface: SurfaceTypesEnum.Horganyzott, price: 8532}] },
      { width: 1200, depth: 700, price: [{ surface: SurfaceTypesEnum.Festett, price: 14909}, { surface: SurfaceTypesEnum.Horganyzott, price: 9706}] },
      { width: 1200, depth: 800, price: [{ surface: SurfaceTypesEnum.Festett, price: 16796}, { surface: SurfaceTypesEnum.Horganyzott, price: 10840}] }
    ];

    this.sole = [
      { price: 557, surface: SurfaceTypesEnum.Festett },
      { price: 256, surface: SurfaceTypesEnum.Horganyzott }
    ];

    this.screwPrice = 40;
    this.cornerPrice = 67;
    this.multiplier = 1;

    this.init();

    let heights: number[] = [];
    for (let i = 0; i < this.columns.length; i++) {
      heights.push(this.columns[i].height);
    }
    this.setHeights(heights);
  }

  calculatePrice() {
    this.logger.log("calculatePrice SalgoMetrikusShelfCalculator");

    let system = this.getSystem();
    this.logger.log(system);

    let systemPrice = 0;
    let currentSurface = system.surface;
    let depth = system.depth;

    let columnPrice = this.columns
      .find(i => i.height === system.height)!.price
      .find(i => i.surface === currentSurface)!.price;
    let columnsNumber = 4 + (system.fieldsNumber - 1) * 2;
    let currentSolePrice = this.sole.find(i => i.surface === currentSurface)!.price;
    let columnsTotalPrice = (currentSolePrice + (this.screwPrice * 2) + columnPrice) * columnsNumber;
    systemPrice += columnsTotalPrice;

    let fieldsPrice: number[] = [];
    for (let i = 0; i < system.fieldsNumber; i++) {
      let shelfValue = this.shelfs
        .find(current => current.depth === depth && current.width === system.shelfsWidth[i])!.price
        .find(current => current.surface === currentSurface)!.price;

      this.logger.log("Polc ára:", shelfValue);

      fieldsPrice[i] = shelfValue * system.shelfsNumber
        + 3 * (8 * (this.cornerPrice + 3 * this.screwPrice))
        + (system.shelfsNumber - 3) * (4 * 2 * this.screwPrice);

      systemPrice += fieldsPrice[i];
    }

    systemPrice *= this.multiplier;

    this.logger.log("Oszlopok száma:", columnsNumber);
    this.logger.log("Oszlop ára:", columnPrice);
    this.logger.log("Talp ára:", currentSolePrice);
    this.logger.log("Csavar ára:", this.screwPrice);
    this.logger.log("Oszlopok ára:", columnsTotalPrice);
    for (let i = 0; i < system.fieldsNumber; i++) {
      this.logger.log("#" + (i + 1), "mező ára:", fieldsPrice[i]);
    }
    this.logger.log("Rendszer ára:", systemPrice);

    this.setState({
      netPrice: systemPrice,
      grossPrice: systemPrice * 1.27
    })
  }
}

export default SalgoMetrikusShelfCalculator;