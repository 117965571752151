import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";

import { NAVLINKS } from "consts"
import Home from "./Home";
import AprodShelfCalculator from "./ShelfCalculator/Aprod";
import SalgoColosShelfCalculator from "./ShelfCalculator/SalgoColos"
import SalgoMetrikusShelfCalculator from "./ShelfCalculator/SalgoMetrikus"
import SalgoCsavarmentesShelfCalculator from "./ShelfCalculator/SalgoCsavarmentes"
import Contact from "./Contact";

import './App.scss';

export class App extends React.Component<any, { mobileMenuOpened: boolean }> {
    constructor(props) {
        super(props);

        ReactGA.initialize('UA-82262577-2');
        ReactGA.pageview(window.location.pathname);

        this.state = {
            mobileMenuOpened: false
        }

        this.mobileMenuChange = this.mobileMenuChange.bind(this);
    }

    private mobileMenuChange() {
        this.setState({ mobileMenuOpened: !this.state.mobileMenuOpened });
    }

    private navClassNames() {
        let classNames: string[] = ["navbar-nav", "mr-auto"];

        if (this.state.mobileMenuOpened) {
            classNames.push("mobile-opened");
        }

        return classNames.join(" ");
    }

    public render() {
        return (
            <>
                <header>
                    <div className="header-top">
                        <div className="container">
                            <div className="logo">
                                <a href="/">
                                    <img src="/images/logos/logo.png" alt="logo" />
                                </a>
                                <div className="logo-text">
                                    <div className="main">
                                        Juhász és Társa Bt.
                                    </div>
                                    <div className="sub">
                                        Polcrendszerek, üzletberendezések, csomagolástechnika
                                    </div>
                                </div>
                            </div>
                            <div className="call">
                                <img src="/images/icons/phone-call.svg" alt="telefon" />
                                <div className="text">
                                    <p>+36-20-968-2309</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="container">
                            <nav className="navbar">
                                <div className="mobile-toggle">
                                    <p>Menü</p>
                                    <div className="toggle">
                                        <input type="checkbox" onChange={() => this.mobileMenuChange()} />
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>

                                <ul className={this.navClassNames()}>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Kezdőlap</a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <div className="nav-link">
                                            Polctervező
                                            <img className="arrow" src="/images/icons/dropdown-arrow.svg" alt="dropdrown arrow"></img>
                                        </div>
                                        <div className="main-categories">
                                            <ul>
                                                <li className="nav-item">
                                                    <a className="nav-link main-category" href={NAVLINKS.polctervezo.aprod}>APROD polcrendszer</a>
                                                    <div className="details">
                                                        <div className="text">
                                                            <p>
                                                                Az APROD kapcsolható polcrendszer sorozatban gyártott, csavarmentes kapcsolattal
                                                                összeállítható elemekből áll, ezáltal rendkívül könnyen szerelhető,
                                                                illetve variálható.
                                                            </p>
                                                        </div>
                                                        <img src="/images/content/aprod.jpg" alt="salgó csavarmentes polcrendszer" />
                                                    </div>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link main-category" href={NAVLINKS.polctervezo.salgoCsavarmentes}>
                                                        Salgó csavarmentes polcrendszer
                                                    </a>
                                                    <div className="details">
                                                        <div className="text">
                                                            <p>
                                                                Azokon a helyeken ahol nincs szükség esztétikus megjelenésre a RAL-skála színeiben,
                                                                kiváló alternatívája lehet az APROD kapcsolható polcos állványnak, hiszen kedvező
                                                                árszintjével vezető pozíciót foglal el a magyar piacon. Kiválóan alkalmas mind az
                                                                otthoni, irattári vagy különböző raktározási célokra.
                                                            </p>
                                                        </div>
                                                        <img src="/images/content/salgo-csavarmentes.jpg" alt="salgó csavarmentes polcrendszer" />
                                                    </div>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link main-category" href={NAVLINKS.polctervezo.salgoMetrikus}>
                                                        Salgó metrikus polcrendszer
                                                    </a>
                                                    <div className="details">
                                                        <div className="text">
                                                            <p>
                                                                A salgó csavarkötésű polcrendszer világszerte a legelterjedtebb DEXION kompatibilis
                                                                állványrendszer. Általánosan felhasználható mind az otthoni, irodai vagy különböző
                                                                raktározási feladatokra.
                                                            </p>
                                                        </div>
                                                        <img src="/images/content/salgo.jpg" alt="salgó metrikus polcrendszer" />
                                                    </div>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link main-category" href={NAVLINKS.polctervezo.salgoColos}>
                                                        Salgó colos polcrendszer
                                                    </a>
                                                    <div className="details">
                                                        <div className="text">
                                                            <p>
                                                                A salgó csavarkötésű polcrendszer világszerte a legelterjedtebb DEXION kompatibilis
                                                                állványrendszer. Általánosan felhasználható mind az otthoni, irodai vagy különböző
                                                                raktározási feladatokra.
                                                            </p>
                                                        </div>
                                                        <img src="/images/content/salgo.jpg" alt="salgó colos polcrendszer" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={NAVLINKS.webshop} target="_blank">Webshop</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={NAVLINKS.kapcsolat}>Kapcsolat</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <BrowserRouter>
                        <Route exact path="/" component={Home} />
                        <Route path={NAVLINKS.polctervezo.aprod} component={AprodShelfCalculator} />
                        <Route path={NAVLINKS.polctervezo.salgoCsavarmentes} component={SalgoCsavarmentesShelfCalculator} />
                        <Route path={NAVLINKS.polctervezo.salgoColos} component={SalgoColosShelfCalculator} />
                        <Route path={NAVLINKS.polctervezo.salgoMetrikus} component={SalgoMetrikusShelfCalculator} />
                        <Route path={NAVLINKS.kapcsolat} component={Contact} />
                    </BrowserRouter>
                </div>
                <footer className="margin-top-40">
                    <div className="container">
                        <p className="copyright">
                            Copyright © 2021 Juhász és Társa Bt.
                        </p>
                    </div>
                </footer>
                <CookieConsent
                    containerClasses="cookie-consent"
                    location="none"
                    disableStyles
                    buttonText="Rendben" >
                    Tájékoztatjuk, hogy a honlap felhasználói élmény fokozásának érdekében sütiket
                    alkalmazunk. A honlapunk használatával ön a tájékoztatásunkat tudomásul veszi.
                    <a href={NAVLINKS.adatkezeles}>Adatkezelési tájékoztató</a>
                </CookieConsent>
            </>
        );
    }
}

export default App;