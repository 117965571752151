export const NAVLINKS = {
    "polctervezo": {
        "aprod": "/polctervezo/aprod",
        "salgoCsavarmentes": "/polctervezo/salgo-csavarmentes",
        "salgoMetrikus": "/polctervezo/salgo-metrikus",
        "salgoColos": "/polctervezo/salgo-colos"
    },
    "kapcsolat": "/kapcsolat",
    "adatkezeles": "https://www.juhaszpolcrendszer.hu/adatkezelesi-tajekoztato-es-szabalyzat/",
    "aszf": "https://www.juhaszpolcrendszer.hu/aszf/",
    "webshop": "https://www.juhaszpolcrendszer.hu/"
};

export const SITE = {
    "title": "Polcrendszerek, üzletberendezések, csomagolástechnika"
}
