import * as React from 'react';
import ReactDOM from 'react-dom';
import Maintenance from "./components/Maintenance";
import App from "./components/App";

import * as serviceWorker from './serviceWorker';

//if (process.env.REACT_APP_PRODUCTION === "true"){
    ReactDOM.render(<App />, document.getElementById('root'));
//}
//else{
//    ReactDOM.render(<Maintenance />, document.getElementById('root'));
//}

serviceWorker.unregister();