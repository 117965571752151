import { StandardShelfCalculator } from './../Standard';
import { IStand } from "./Model/IStand";
import { SurfaceTypesEnum } from '../Standard/Enum/SurfaceTypesEnum'
import { ISelect } from '../Standard/Model/ISelect';

export class SalgoCsavarmentesShelfCalculator extends StandardShelfCalculator {
  // Állványok
  private stands: IStand[] = [];

  constructor(props) {
    super(props);

    this.type = "salgo-csavarmentes";
    this.name = "Salgó csavarmentes polcrendszer tervező";
    this.previewImage = "/images/content/preview/salgo-csavarmentes.jpg";

    this.surfaces = [SurfaceTypesEnum.Horganyzott];
    this.shelfsNumber = [4, 5, 6, 7];
    this.fieldsNumber = [1, 2, 3, 4];

    this.stands = [
      { height: 3000, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 14801}] },
      { height: 3000, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 14072}] },
      { height: 3000, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 13316}] },
      { height: 3000, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 12625}] },
      { height: 3000, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 12201}] },
      { height: 3000, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 11800}] },
      { height: 3000, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 11679}] },
      { height: 2500, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 11895}] },
      { height: 2500, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 11376}] },
      { height: 2500, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 10761}] },
      { height: 2500, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 10217}] },
      { height: 2500, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9881}] },
      { height: 2500, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9585}] },
      { height: 2500, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9796}] },
      { height: 2000, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9661}] },
      { height: 2000, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9221}] },
      { height: 2000, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 8724}] },
      { height: 2000, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 8278}] },
      { height: 2000, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 8004}] },
      { height: 2000, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 7757}] },
      { height: 2000, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 7896}] },
      // { height: 1600, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 6133}] },
    ];

    this.shelfs = [
      { width: 1800, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 15696}] },
      { width: 1800, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 14052}] },
      { width: 1800, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 12911}] },
      { width: 1800, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 11177}] },
      { width: 1800, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 10151}] },
      { width: 1800, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9410}] },
      { width: 1800, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 8756}] },
      { width: 1500, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 10450}] },
      { width: 1500, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 9133}] },
      { width: 1500, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 8177}] },
      { width: 1500, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 6719}] },
      { width: 1500, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 5858}] },
      { width: 1500, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 5235}] },
      { width: 1500, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 4687}] },
      { width: 1200, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 8357}] },
      { width: 1200, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 7363}] },
      { width: 1200, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 6591}] },
      { width: 1200, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 5411}] },
      { width: 1200, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 4716}] },
      { width: 1200, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 4211}] },
      { width: 1200, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 3768}] },
      { width: 900, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 6249}] },
      { width: 900, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 5430}] },
      { width: 900, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 4859}] },
      { width: 900, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 3991}] },
      { width: 900, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 3478}] },
      { width: 900, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 3106}] },
      { width: 900, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 2781}] },
      { width: 600, depth: 800, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 4223}] },
      { width: 600, depth: 700, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 3726}] },
      { width: 600, depth: 600, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 3340}] },
      { width: 600, depth: 500, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 2749}] },
      { width: 600, depth: 400, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 2400}] },
      { width: 600, depth: 320, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 2149}] },
      { width: 600, depth: 250, price: [{ surface: SurfaceTypesEnum.Horganyzott, price: 1928}] },
    ];

    this.multiplier = 1.0;

    this.init();

    let heights: number[] = [];
    for (let i = 0; i < this.stands.length; i++) {
      heights.push(this.stands[i].height);
    }
    this.setHeights(heights);
  }

  calculatePrice() {
    this.logger.log("calculatePrice SalgoCsavarmentesShelfCalculator");

    let system = this.getSystem();
    this.logger.log(system);

    let systemPrice = 0;
    let standsNumber = system.fieldsNumber + 1;
    this.logger.log("Állványok száma:", standsNumber);

    let standPrice = this.stands
      .find(currenct => currenct.depth === system.depth && currenct.height === system.height)?.price
      .find(current => current.surface === system.surface)?.price ?? 0;

    this.logger.log("Állvány ára:", standPrice);
    systemPrice += standsNumber * standPrice;

    for (let i = 0; i < system.fieldsNumber; i++) {
      let shelfValue = this.shelfs
        .find(current => current.depth === system.depth && current.width === system.shelfsWidth[i])!.price
        .find(current => current.surface === system.surface)!.price;

      this.logger.log("#" + (i + 1) + " Polc ára:", shelfValue);

      systemPrice += shelfValue * system.shelfsNumber;
    }

    systemPrice *= this.multiplier;

    this.logger.log("Rendszer ára:", systemPrice);

    this.setState({
      netPrice: systemPrice,
      grossPrice: systemPrice * 1.27
    })
  }

  checkShelfsDepth(current: ISelect) {
    const system = this.getSystem();
    const currentDepth = current.value;

    let standPrice = this.stands
      .find(current => current.depth === currentDepth && current.height === system.height)?.price
      .find(current => current.surface === system.surface)?.price ?? 0;

    return standPrice ? true : false;
  }
}

export default SalgoCsavarmentesShelfCalculator;